export const members = {
    methods: {
        memberRights(droits) {
            let ok = 0;
            droits.forEach(droit => {
                console.log('droit ' + droit)
                if (this.memberMeta(droit)) {
                    console.log('ok')
                    ok++;
                }
            });

            return ok.length == droits.length;
        },
        valeurAfficherAvatar(valeur) {
            if (valeur == 'never') {
                valeur = false;
            }
            return valeur;
        },
        memberMeta(cle, params = {}) {
            let meta = false;

            if ('meta' in params) {
                meta = params.meta;
            } else {
                let member = 'member' in params ? params.member : this.loggedMember();
                if (member) {
                    meta = member.meta
                }
            }
            if (meta) {
                if (cle in meta) {
                    let valeur = meta[cle];
                    if (valeur != 'undefined') {
                        if ('callback' in params) {
                            valeur = params.callback(valeur);
                        }
                        return valeur;
                    }
                }
            }
            return 'default' in params ? params.default : null;
        },
        estBloque(data = null) {
            if (data === null) {
                data = this.loggedMember()
            }
            if (data) {
                if ('memberActive' in data) {
                    return !data.memberActive
                } else {
                    return !data.active || data.active == '0';
                }
            }
        },
        enAttente(data = null) {
            if (data === null) {
                data = this.loggedMember()
            }
            if (data) {
                let active;
                if ('memberActive' in data) {
                    active = data.memberActive
                } else {
                    active = data.active || data.active != '0';
                }
                if(!active) {
                    if(data.comments == -1) {
                        return true;
                    }
                }
            }
        },
        estMasque(member = null) {
            if (member === null) {
                member = this.loggedMember()
            }
            if (member) {
                if ('memberHidden' in member) {
                    return member.memberHidden == '1'
                } else {
                    return member.hidden == '1';
                }
            }
        },
        loggedMember(field = false) {
            if (this.$store.state.member) {
                if (field) {
                    return this.$store.state.member[field];
                } else {
                    return this.$store.state.member;
                }
            }
        }
    },
    computed: {

        isAdmin() {
            if (this.isConnected && this.$store.state.member.admin) {
                return this.$store.state.member;
            }
        },
        isConnected() {
            return this.$store.state.member ? this.$store.state.member : false;
        },
        afficherOngletNotifications() {
            if (!this.isConnected) {
                return false;
            }
            if ('meta' in this.$store.state.member) {
                if ('recevoirNotifications' in this.$store.state.member.meta) {
                    return this.$store.state.member.meta.recevoirNotifications;
                }
            }
            return true;
        }
    }
}