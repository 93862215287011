export const commons = {
    data() {
        return {
            tab: 'all'
        }
    },
    computed: {
        storyId() {
            return this.$route.params.storyId;
        },
        storyFermee() {
            let meta = this.objVal(this.$store.state.story, 'meta');
            return this.objVal(meta, 'fermer') ? true : false;
        },
        site() {
            return this.$store.state.site;
        },
        story() {
            return this.$store.state.story;
        },
        pages() {
            return this.$store.state.pages;
        },
        page() {
            return this.$store.state.page;
        },
        customCss() {
            // console.log('this.$store.state.params',this.$store.state.params)
            if (this.$store.state.params && 'customCss' in this.$store.state.params) {
                return this.$store.state.params.customCss
            }
        },
        affichageChronologique() {
            return this.$store.getters.displayMode == 'chronologie'
        },
        isLive() {
            return this.objVal(this.story, 'isLive');
            // return this.objVal(this.story, 'isLive') || this.$route.query.isLive == 'true';
        },
        isReduced() {
            return this.$route.query.isReduced == 'true';
        },
        isLoading() {
            return this.$store.state.loading ? 'is-loading' : false;
        },
        specificCommentId() {
            if (this.$store.state.commentId) {
                return this.$store.state.commentId;
            } else if (this.$route.params.commentId) {
                return this.$route.params.commentId;
            }
        }
    },
    methods: {
        sendNotificationForComment(name, comment) {
            let uniqueId = comment.id;
            let payload = {
                comment: comment,
                story: this.story,
            };
            api.post("notification", {
                name: name,
                uniqueId,
                payload,
                memberId: comment.memberId,
            });
        },
        sendNotificationForMember(name, member, uniqueId=false) {
            api.post("notification", {
                uniqueId: uniqueId ? uniqueId : member.id,
                name: name,
                payload : {member:member},
                memberId: member.id,
            });
        },
        notifAdmin(condition = true) {
            if (condition) {
                return confirm('Action effectuée. Voulez-vous que le membre soit notifié ?');
            }
        },
        scrollToElement(element) {
            if (element) {
                this.triggerEvent(
                    "scrollTo",
                    this.getOffsetTop(element)
                );
            }
        },
        scrollToComment(params) {
            // console.log('scrollToComment', params)
            let id = params.commId ? params.commId : params;
            let focused = this.objVal(params, 'focus');
            if (focused) {
                this.$store.commit('setFocused', id);
            }
            let selector = '[data-id="' + id + '"]';
            if (document.querySelector(selector)) {
                this.triggerEvent(
                    "scrollTo",
                    this.getOffsetTop(selector)
                );
            } else {
                this.$store.commit('setCommentId', id);
                this.$bus.$emit('load-story');
            }
            /*            let storyId = params.storyId ? params.storyId : false;
            
                        let selector = '[data-id="' + id + '"]';
                        // TODO faut aller le chercher le comm, s'il est dans une autre page
                        this.triggerEvent(
                            "scrollTo",
                            this.getOffsetTop(selector)
                        );*/
        },
        /** utiliser pour aller de l'embed d'un commentaire a la position du commentaire "en contexte" */
        jumpToComment(id) {
            this.triggerEvent(
                "jumpTo",
                id
            );
        },
        storyMeta(meta) {
            if (this.story && this.story.meta && this.story.meta[meta] in this.story.meta) {
                console.log(meta, this.story.meta)
                return this.story.meta[meta];
            }
        },
        dateHeureFr(str) {
            let d = new Date(str);
            return d.toLocaleDateString('fr-FR') + ' à ' + d.toLocaleTimeString('fr-FR');
        },
        setPageTitle(title) {
            if (title) {
                document.title = this.decodeEntities(title.replace(/(<([^>]+)>)/ig, ""));
            }
        },
        setStory(story) {
            this.$store.commit('setStory', story);
            this.$bus.$emit('charger-token-depuis-query');
        },
        startLoading() {
            return this.$store.commit('setLoading', true);
        },
        endLoading() {
            return this.$store.commit('setLoading', false);
        }
    }
}