import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/:site/story/:storyId',
    name: 'Story',
    component: () => import('../views/Story.vue')
  },
  {
    path: '/:site/story/:storyId/comment/:commId',
    name: 'Comment',
    component: () => import(/* webpackChunkName: "comment" */'../views/Comment.vue'),
    props : {
      site: route => ({ search: route.params.site })      
    }
  },
  {
    path: '/:site/story/:storyId/tab/:tab',
    name: 'StoryTab',
    component: () => import(/* webpackChunkName: "tabs" */ '../views/Story.vue')
  },
/*  {
    path: '/:site/story/:storyId/:commentId',
    name: 'CommentInStory',
    component: () => import('../views/Story.vue')
  },*/
  {
    path: '/:site/admin',
    name: 'Admin',
    component: () => import( /* webpackChunkName: "admin" */ '../views/Admin.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
