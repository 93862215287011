import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    reducer: (state) => {
        return cloner(state, ['sort', 'displayMode', 'brouillons', 'captcha', 'verifAdmin']);
    }
})
Vue.use(Vuex)
const initialState = {
    verifAdmin: false,
    admin: {
        logged: false
    },
    openPost: false,
    captcha: false,
    commentId: false,
    site: false,
    page: 1,
    pages: 1,
    member: false,
    votes: {},
    token: false,
    story: false,
    focused: false,
    loading: false,
    domain: false,
    params: false,
    options: false,
    tab: 'all',
    sort: false,
    displayMode: false,
    reply: false,
    edit: false,
    brouillons: {},
    scrollIntoView: false,
    notifications: 0
};
export default new Vuex.Store({
    plugins: [vuexLocal.plugin],
    state: cloner(initialState),
    mutations: {
        setVerifAdmin(state) {
            state.verifAdmin = true;
        },
        setAdmin(state, member) {
            state.admin.logged = member ? true : false;
            state.admin.member = member
        },
        avancerPage(state) {
            state.page++;
        },
        setPage(state, page) {
            state.page = page;
        },
        setPages(state, pages) {
            state.pages = pages;
        },
        setFocused(state, focused) {
            state.focused = focused;
        },
        switchOpenPost(state) {
            state.openPost = !state.openPost;
        },
        setCommentId(state, commentId) {
            state.commentId = commentId;
        },
        setEdit(state, edit) {
            state.edit = edit;
        },
        setScrollIntoView(state, scrollIntoView) {
            state.scrollIntoView = scrollIntoView;
        },
        setReply(state, reply) {
            state.reply = reply;
        },
        setSort(state, sort) {
            state.sort = sort;
        },
        setDisplayMode(state, displayMode) {
            state.displayMode = displayMode;
        },
        setNotifications(state, notifications) {
            state.notifications = notifications;
        },
        setMember(state, member) {
            state.member = member;
            state.notifications = state.member.notifications;
        },
        setTab(state, tab) {
            state.tab = tab;
        },
        setOption(state, option) {
            state.options[option.cle] = option.valeur;
        },
        setOptions(state, options) {
            state.options = options;
        },
        setCaptcha(state) {
            state.captcha = true;
        },
        setParams(state, params) {
            state.params = params;
        },
        setDomain(state, domain) {
            state.domain = domain;
        },
        setSite(state, site) {
            state.site = site;
        },
        setStory(state, story) {
            state.story = story;
        },
        setLoading(state, loading) {
            state.loading = loading;
        },
        setVotes(state, data) {
            state.votes[data.storyId] = cloner(data.votes);
        },
        setBrouillon(state, payload) {
            let parentId = payload.parentId > 0 ? '-' + payload.parentId : '';
            state.brouillons[payload.storyId + parentId] = payload.brouillon
        },
        deleteBrouillon(state, payload) {
            let parentId = payload.parentId > 0 ? '-' + payload.parentId : '';
            delete state.brouillons[payload.storyId + parentId];
        },
        logOut(state) {
            state.member = false;
            state.votes = {};
            state.token = false;
        },
        logIn(state, data) {
            state.token = data.token;
            state.member = cloner(data.member);
            state.notifications = state.member.notifications;
            state.votes[data.storyId] = cloner(data.votes);
        }
    },
    actions: {
        verifAdmin({ commit, state }, payload) {
            return payload.callback();
            if (state.verifAdmin) {
                payload.callback();
            } else {
                let pass = prompt('Entrez le mot de passe admin');
                if (pass) {
                    api.post('security', { pass }).then(response => {
                        commit('setVerifAdmin');
                        if (payload.callback) {
                            payload.callback();
                        }
                    }).catch(error => {
                        alert(error.response.data.message);
                    });
                }
            }
        },
        loadToken({ commit, state }, params) {
            const storyId = 'storyId' in params ? params.storyId : false;
            const token = 'token' in params ? params.token : state.token;
            if (token) {
                console.log(token);
                return api
                    .post(`jwt`, { token: token, storyId: storyId })
                    .then((response) => {
                        console.log(response.data);
                        commit("logIn", {
                            storyId: storyId,
                            token: token,
                            member: response.data.member,
                            votes: response.data.votes,
                        });
                    }).catch(error => {
                        if (error.response.data.message) {
                            params.app.$bus.$emit('member-message', error.response.data.message);
                        }

                    })

            }
        },
        updateMemberMeta({ commit, state }, payload) {
            api.post(`member/${state.member.id}/meta`, { storyId: payload.storyId, meta: payload.meta }).then((response) => {
                commit('setMember', response.data)
                if ('callback' in payload) {
                    payload.callback();
                }
            });
        },

        chargerOptions(context, payload) {
            context.commit('setOptions', false);

            payload.vue.smartApi(`options`, {
                chargerParCdn: true,
                then(data) {
                    context.commit('setOptions', data);
                },
            });

            /*            api.get('options').then(response => {
                            context.commit('setOptions', response.data);
                        })*/
        }
    },
    getters: {
        brouillon: (state) => (payload) => {
            let parentId = payload.parentId > 0 ? '-' + payload.parentId : '';
            return state.brouillons[payload.storyId + parentId];
        },
        captcha: (state) => {
            return state.captcha
        },
        story: (state) => {
            return state.story
        },
        storyId: (state) => {
            return state.story.storyId
        },
        edit: (state) => {
            return state.edit
        },
        scrollIntoView: (state) => {
            return state.scrollIntoView
        },
        replyTo: (state) => {
            return state.reply.replyTo
        },
        quote: (state) => {
            if (state.reply.quote) {
                return '[quote]' + state.reply.quote + '[/quote]\n'
            }
        },
        sort: (state) => {
            return state.sort
        },
        displayMode: (state) => {
            return state.displayMode
        },
        upvotes: (state) => (storyId) => {
            if (state.votes &&
                state.votes[storyId] &&
                state.votes[storyId].upvotes) {
                return state.votes[storyId].upvotes;
            } else {
                return [];
            }
        },
        downvotes: (state) => (storyId) => {
            if (state.votes &&
                state.votes[storyId] &&
                state.votes[storyId].upvotes) {
                return state.votes[storyId].downvotes;
            } else {
                return [];
            }
        },
        options(state) {
            return state.options;
        },
        option: (state) => (cle) => {
            return state.options[cle];
        },
        tagsPreferes(state) {
            if(!state.options || state.options['tags-preferes']) {
                return [];
            }
            return state.options['tags-preferes'].split('\n').map((element) => element.trim());
        }
    },
    modules: {}
})